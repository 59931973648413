<template>
<div style="background-color:white">
    <div class="col-md-12" style="margin-top:20px">
        <div class="col-md-6">
            <h3 class="page-header">
            <i class="fa fa fa-user animated bounceInDown show-info"></i>
                Add Running Schemes
            </h3>
        </div>
    </div>
    <div class="info-box  bg-seagreen  text-white">
        <div class="info-icon bg-danger-dark">
            <i class="fa fa fa-user fa-2x"></i>
        </div>
        <div class="info-details">
            <h4>Add Schemes</h4>
        </div>
    </div>
    <form class="col-md-12" @submit.prevent="onSubmit" @keydown="keydownform($event)">
        <div class="panel panel-cascade">
            <div class="panel-body" >
                <div class="form-horizontal cascde-forms">
                    <referral-selector label="Customer ID" col="col-lg-3 col-md-3"></referral-selector>
                    <div class="form-group row">
                        <label class="col-lg-4 col-md-3 control-label">Customer Introducer<span style="color:rgba(255, 0, 0, 0.67)">*</span></label>
                        <div class="col-lg-8 col-md-9" v-if="referraluser!=null && referraluser.referraluser!=null">
                            <span style="font-weight:700">{{ referraluser.referraluser.generatedid }}</span> -- {{ referraluser.referraluser.name }}
                            <display-mobiles :mobiles="referraluser.referraluser.mobile"></display-mobiles>
                            <display-first-address :addressess="referraluser.addressess"></display-first-address>
                        </div>
                    </div>
                    <div class="form-group row" :class="{ 'form-group--error': $v.form.schemeid.$error }">
                        <label class=" col-lg-4 col-md-3 control-label">
                        Scheme Name<span style="color: rgba(255, 0, 0, 0.67)">*</span>
                        </label>
                        <div class="col-lg-6 col-md-9">
                            <select class="form-control form-cascade-control input-small .dropdown-menu" style="background-color: rgb(255, 255, 255);" v-model="$v.form.schemeid.$model" required>
                                <option v-for="(item) in schemes" v-bind:key="item.id" :value="item.id">{{ item.name }}</option>
                            </select>
                            <span class="error" v-if="!$v.form.schemeid.required">Field is required</span>
                        </div>
                    </div>
                    <div class="form-group row" :class="{ 'form-group--error': $v.form.schemeid.$error }">
                        <label class="col-lg-4 col-md-3 control-label">Sale Date<span style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                        <div class="col-lg-6 col-md-9">
                            <input class="form-control form-cascade-control input-small" v-model="$v.form.saledate.$model" type="date">
                            <span class="error" v-if="!$v.form.saledate.required">Sale Date is required</span>
                             <div class="error" v-if="!$v.form.saledate.isUnique">Input Valid Date</div>
                        </div>
                    </div>
                        <div class="form-group row" :class="{ 'form-group--error': $v.form.startdate.$error }">
                            <label class="col-lg-4 col-md-3 control-label">Start Date<span style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                            <div class="col-lg-6 col-md-9">
                                <input class="form-control form-cascade-control input-small" v-model="$v.form.startdate.$model" type="date">
                                <span class="error" v-if="!$v.form.startdate.required">Sale Date is required</span>
                                <div class="error" v-if="!$v.form.startdate.isUnique">Input Valid Date</div>
                            </div>
                        </div>
                    <div class="form-group row" :class="{ 'form-group--error': $v.form.startdate.$error }">
                        <label class=" col-lg-4 col-md-3 control-label">
                        Deal By<span style="color: rgba(255, 0, 0, 0.67)">*</span>
                        </label>
                        <div class="col-lg-6 col-md-9">
                          <select class="form-control form-cascade-control input-small .dropdown-menu" style="background-color: rgb(255, 255, 255);" v-model="form.sellerid" required>
                              <option v-for="(item) in employees" v-bind:key="item.id" :value="item.id">{{ item.name }}</option>
                          </select>
                          <span class="error" v-if="!$v.form.sellerid.required">Sale Date is required</span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-4 col-md-3">
                            <label class="col-form-label control-label">Sale By</label>
                        </div>
                        <div class="col-md-6">
                            <input type="text" placeholder="VSDIGI-ID" class="form-control form-cascade-control input-small" @change="checkadvisor()" v-model="useradvisorid">
                            
                                <span v-show="isadvisor">
                                <strong style="color:green">{{this.advisorid.name}} is a Advisor</strong>
                            </span>
                            <span v-show="showadvice">
                                <strong style="color:red">Not a Advisor</strong>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12">   
            <div class="row" style="text-align:center;">
                <input type="button" style="background-color:#495b79!important;margin-top:23px;margin-left:35px;" value="Submit" @click="submit()" class="btn bg-primary text-white btn-sm chkcondition">
                <input type="button" style="background-color:#495b79!important;margin-top:23px;margin-left:35px;" value="Cancel" @click="cancel()" class="btn bg-primary text-white btn-sm chkcondition">
            </div>
        </div>
    </form>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import Constants from '../../components/utilities/Constants.vue'
import ReferralSelector from '../../components/user/ReferralSelector.vue'
import { required } from 'vuelidate/lib/validators'
import DisplayFirstAddress from '../../components/address/DisplayFirstAddress.vue'
import DisplayMobiles from '../../components/mobile/DisplayMobiles.vue'
import moment from 'moment'
export default {
    mixins:[Constants],
     components:{ReferralSelector,DisplayFirstAddress,DisplayMobiles},
    data(){
      return {
            form: new window.Form({
                id:0,
                parentid:0,
                customerid:0,
                schemeid:0,
                sellerid:0,
                saledate:'',
                startdate:'',
                
            }),
                showadvice:false,
                isadvisor:false,
                useradvisorid:'',
                moment:moment
      }
    },
    validations: {
        form:{
            schemeid:{
                required
            },
            sellerid:{
                required
            },
            saledate:{
                required,
                isUnique(value){
                    if(value===null){
                        return false
                    }
                    return new Promise((resolve) => {
                        setTimeout(() => {
                            resolve(moment(value).isValid())
                        }, 350 + Math.random() * 300)
                    })
                }
            },
            startdate:{
                required,
                isUnique(value){
                    if(value===null){
                        return false
                    }
                    return new Promise((resolve) => {
                        setTimeout(() => {
                            resolve(moment(value).isValid())
                        }, 350 + Math.random() * 300)
                    })
                }
            }
        }
    },
    computed:{
       ...mapGetters([
		     'viewno','edititem','schemes','customers','employees','referraluser','advisorid'
		    ]),
    },
    mounted(){
          this.initializedata();
          this.$store.dispatch('fetchschemes');
          this.$store.dispatch('fetchemployees');
	},
    watch:{
          edititem:function(){ 
            this.initializedata();
          }
    },
    methods:{
        initializedata(){
            this.reset();
            if (this.edititem!=null){
                this.form.id=this.edititem.id;
                this.form.customerid=this.edititem.customerid;
                this.form.schemeid=this.edititem.schemeid;
                this.form.sellerid=this.edititem.sellerid;
                this.form.saledate=this.edititem.saledate;
                if(this.edititem.advisor!=null){
                    this.useradvisorid=this.edititem.advisor.generatedid
                    this.checkadvisor();
                }
                this.form.startdate=this.edititem.startdate;  
                this.$store.commit('assignreferraluser',this.edititem.customer)            
            }else{
                this.form.customerid='';
                this.form.schemeid=0;
                this.form.sellerid=0;
                this.form.saledate='';
                this.useradvisorid=''
                this.form.startdate='';  
                this.$store.commit('assignreferraluser',null)
            }
        },
        keydownform(event){
            this.form.errors.clear(event.target.name);
            this.errormessage = '';
		    },
        submit(){
            console.log(this.referraluser)
            if (this.$v.$invalid || this.referraluser==null){
                this.$notify({
                    text:'Please Fill All the fields',
                    type:'error',
                    duration:'-1',
                    speed:'100',
                    closeOnClick:false
                })
            }else{    
                this.form.customerid=this.referraluser==null?0:this.referraluser.id;
                let param={advisorid:this.advisorid.id,id:0,
                    parentid:this.form.parentid,
                    customerid:this.form.customerid,
                    schemeid:this.form.schemeid,
                    sellerid:this.form.sellerid,
                    saledate:this.form.saledate,
                    startdate:this.form.startdate,
                    fy:2022
                }
                this.$notify({
                    text:'Submitted, On Process',
                    type:'warn',
                    duration:'-1',
                    speed:'100',
                    closeOnClick:false
                })
                window.axios.post('api/ongoingschemes/createupdate',param)
                .then(response=>this.processResponse(response))
                .catch(error=>console.log(error));
            }
        },
        processResponse(data){
            this.$store.commit('updateappendongoingschemes',data)
            this.$store.commit('assignviewno',1)
            this.$notify({
					text:'Registered Successfully',
					type:'success',
					duration:'-1',
					speed:'100',
					closeOnClick:false
					
            })
                this.initializedata();
                this.id=0
        }, 
        cancel(){
            this.reset();
            this.$store.commit('assignviewno',1)
        },
        checkadvisor(){
            let param={advisorid:this.useradvisorid}
            window.axios.post('api/user/downline/fetch/check',param)
    			.then(response=>this.processAdiveResponse(response.data))
    			.catch(error=>console.log(error));
        },
        processAdiveResponse(data){
            if(data.success==true){
                //alert('Advisor')
                //this.advisordata=data.data
                this.$store.commit("assignadvisorid",data.data)
                this.isadvisor=true
                this.showadvice=false
            }else{
                this.showadvice=true
                this.isadvisor=false
                //alert("not a Advisor")
            }
        },
        
	  }
}
</script>